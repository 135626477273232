import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Gift = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>gift (mini project #5)</h2>
        <p>Build a Chrome Extension for a specific person. What would that person enjoy?</p>
        <p>Make use of the techniques reviewed in Lab #6. The main goal is to use a background script and at least on of the <a target="_blank" href="https://developer.chrome.com/extensions/api_index">Chrome APIs</a>.</p>
    
        <br></br>
        <p>Add a README.md file to your project that includes a short description and a screenshot of the way in which it alters websites.</p>
        <p>Compress the whole extension folder into a .zip file. In your projects folder should be one "gift-project" (with the code) folder and one "gift-project.zip" file.</p>
        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy.</p>
        <p>2. in your GitHub, find the "gift-project.zip" file and copy the link.</p>
        <p>3. and link to the "bug-project.zip" file to your Room (the idea is that we can easily download your project).</p>
      </MiniLayout>
    </Layout>
  )
}



export default Gift
